.PopperArrow {
  position: absolute;
}

.PopperArrow__in {
  content: '';
  display: block;

  /* см. Примечание 1 в PopperArrow.tsx. */
  transform: translateY(1px);
}

.PopperArrow--placement-right {
  transform: rotate(90deg) translate(50%, -50%);
  transform-origin: right;
}

.PopperArrow--placement-bottom {
  transform: rotate(180deg);
}

.PopperArrow--placement-left {
  transform: rotate(-90deg) translate(-50%, -50%);
  transform-origin: left;
}
