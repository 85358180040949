.CellCheckbox {
  display: flex;
  justify-content: center;
  width: 24px;
}

.CellCheckbox__icon {
  display: none;
  color: var(--vkui--color_icon_tertiary);
}

.CellCheckbox__icon--on {
  color: var(--vkui--color_icon_accent);
}

.CellCheckbox__input:not(:checked) ~ .CellCheckbox__icon--off,
.CellCheckbox__input:checked ~ .CellCheckbox__icon--on {
  display: block;
}
