/* @font-face {
    font-family: 'Bukyvede';
    src: url('./bukyvede.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bukyvede';
    src: url('/fonts/bukyvede_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Fitavjaz';
    src: url('./fitavjaz.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */
@font-face {
    font-family: 'Vezitsa';
    src: url('./Vezitsa.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}