.RadioGroup--mode-horizontal {
  display: flex;
}

/**
 * CMP:
 * FormItem
 */
:global(.vkuiInternalFormItem) .RadioGroup {
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
  box-sizing: content-box;
}
