.SliderThumb {
  position: absolute;
  z-index: 1;
  width: var(--vkui_internal--slider_thumb_size);
  height: var(--vkui_internal--slider_thumb_size);
  border-radius: 50%;
  border: var(--vkui--size_border--regular) solid var(--vkui--color_separator_primary_alpha);
  background: var(--vkui--color_background_contrast);
  box-shadow: var(--vkui--elevation3);
  user-select: none;
}

.SliderThumb--focus-visible {
  outline: var(--vkui_internal--outline);
  outline-offset: calc(-1 * var(--vkui--size_border--regular));
  z-index: 2;
}

/**
 * Задаём ширину и высоту родителя, чтобы скринридер правильно выделял элемент (скопировано с MUI).
 */
.SliderThumb__nativeInput {
  direction: ltr;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -1px;
  padding: 0;
  border: 0 none;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
}
