.SubnavigationBar__in {
  /* Отрицательный margin нужен, чтобы компенсировать увеличенный из-за тени padding (12px -> 20px) */
  margin: -8px 0;
}

.SubnavigationBar__scrollIn {
  margin: 0;
  padding: calc(var(--vkui--size_subnavigation_bar_padding_vertical--regular) + 8px) 0;
  display: flex;
}

.SubnavigationBar__scrollIn::before,
.SubnavigationBar__scrollIn::after {
  content: '';
  min-width: var(--vkui--size_base_padding_horizontal--regular);
}

.SubnavigationBar__item {
  list-style-type: none;
  display: flex;
}

.SubnavigationBar__item + .SubnavigationBar__item {
  margin-left: var(--vkui--size_subnavigation_bar_gap--regular);
}

/**
 * CMP:
 * SubnavigationBar
 */

:global(.vkuiInternalSubnavigationBar--mode-fixed) .SubnavigationBar__item {
  flex: 1;
  min-width: 0;
}
