.Radio {
  display: block;
  padding: 0 var(--vkui--size_base_padding_horizontal--regular);
  font-family: var(--vkui--font_family_base);
}

/**
 * CMP:
 * FormItem
 */
:global(.vkuiInternalFormItem) .Radio {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.Radio__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Radio__input:disabled ~ .Radio__container {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Radio__icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  color: var(--vkui--color_icon_tertiary);
  transition: color 0.15s var(--vkui--animation_easing_platform);
}

.Radio__input:checked ~ .Radio__container .Radio__icon {
  color: var(--vkui--color_icon_accent);
}

.Radio__icon .Radio__pin {
  transform-origin: 12px 12px;
  transform: scale(0);
  transition: transform 0.15s var(--vkui--animation_easing_platform);
}

.Radio__input:checked ~ .Radio__container .Radio__icon .Radio__pin {
  transform: none;
}

.Radio__content {
  display: block;
  flex-grow: 1;
  max-width: 100%;
  color: var(--vkui--color_text_primary);
}

.Radio__description {
  display: block;
  color: var(--vkui--color_text_secondary);
  margin-bottom: 12px;
  margin-top: 2px;
}

.Radio__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.Radio__title:last-child {
  margin-bottom: 12px;
}

.Radio__titleAfter {
  margin-left: 4px;
  color: var(--vkui--color_icon_tertiary);
}

/**
 * sizeY COMPACT
 */

.Radio--sizeY-compact .Radio__title {
  margin-top: 8px;
}

.Radio--sizeY-compact .Radio__title:last-child {
  margin-bottom: 8px;
}

.Radio--sizeY-compact .Radio__description {
  margin-bottom: 7px;
}

.Radio--sizeY-compact .Radio__icon {
  width: 18px;
  height: 18px;
}

.Radio--sizeY-compact .Radio__icon::after {
  width: 12px;
  height: 12px;
}

@media (--sizeY-compact) {
  .Radio--sizeY-none .Radio__title {
    margin-top: 8px;
  }

  .Radio--sizeY-none .Radio__title:last-child {
    margin-bottom: 8px;
  }

  .Radio--sizeY-none .Radio__description {
    margin-bottom: 7px;
  }

  .Radio--sizeY-none .Radio__icon {
    width: 18px;
    height: 18px;
  }

  .Radio--sizeY-none .Radio__icon::after {
    width: 12px;
    height: 12px;
  }
}

/**
 * CMP:
 * RadioGroup
 */
:global(.vkuiInternalRadioGroup) .Radio {
  flex: 1;
  margin: 0;
  box-sizing: border-box;
}
