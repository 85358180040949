/* stylelint-disable @project-tools/stylelint-atomic */
.AccordionSummary::-webkit-details-marker {
  display: none;
}

.AccordionSummary__icon {
  color: var(--vkui--color_icon_secondary);
}

/* stylelint-disable-next-line selector-max-type -- Old code */
.Accordion:not([open]) > summary .AccordionSummary__icon--collapse {
  display: none;
}

/* stylelint-disable-next-line selector-max-type -- Old code */
.Accordion[open] > summary .AccordionSummary__icon--expand {
  display: none;
}
