.RichCellIcon {
  width: 48px;
  height: 40px;
  box-sizing: border-box;
  color: var(--vkui--color_icon_accent_themed);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 0 6px 12px;
}
