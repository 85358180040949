.TextTooltip {
  border-radius: var(--vkui--size_border_radius--regular);
  background-color: var(--vkui--color_background_modal);
  padding: 8px 12px 9px;
  color: var(--vkui--color_text_primary);
  box-shadow: var(--vkui--elevation3);
  animation: texttooltip-fade-in 0.2s ease;
}

.TextTooltip__arrow {
  color: var(--vkui--color_background_modal);
}

.TextTooltip--appearance-accent {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.TextTooltip--appearance-accent .TextTooltip__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.TextTooltip--appearance-white {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.TextTooltip--appearance-white .TextTooltip__arrow {
  color: var(--vkui--color_background_contrast);
}

.TextTooltip--appearance-black {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.TextTooltip--appearance-black .TextTooltip__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.TextTooltip--appearance-inversion {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.TextTooltip--appearance-inversion .TextTooltip__arrow {
  color: var(--vkui--color_background_modal_inverse);
}

@keyframes texttooltip-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
