/* stylelint-disable-next-line selector-max-type */
.vkui,
.vkui > body,
.vkui__root {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* stylelint-disable-next-line selector-max-type */
.vkui,
.vkui > body {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: var(--vkui--font_family_base);
  color: var(--vkui--color_text_primary);
  color-scheme: var(--vkui--colors_scheme);
}

.vkui__root--embedded {
  overflow-x: hidden;
}

.vkui--sizeX-regular {
  background: var(--vkui--color_background);
}

@media (--sizeX-regular) {
  .vkui--sizeX-none {
    background: var(--vkui--color_background);
  }
}

.vkui--layout-card {
  background: var(--vkui--color_background);
}

.vkui--layout-plain {
  background: var(--vkui--color_background_content);
}

/* отключаем нативный pull-to-refresh при взаимодействии с компонентом
 * PullToRefresh или при открывании модалки */
.vkui--disable-overscroll-behavior {
  overscroll-behavior-y: none;
}
