.ordersContainer .vkuiSimpleCell__middle {
	padding-bottom: 0;
  	padding-top: 0;
}

.orderOpened {
	display:block;
}

.noteList {
	border-top: 1px dashed var(--vkui--color_separator_primary);
	border-bottom: 1px solid var(--vkui--color_separator_primary);
}