.noteNames {
	margin: 1em 0 1em -0.5em;
	/* padding: 0 1em; */
}
.noteNames li {
	border-bottom-width:1px;
	border-bottom-style:dashed;
	padding: 0.2em 1em 0.2em 1em;
  	margin: 0 1.5em 0.5em 0;
	font-size:1em;
	color: var(--vkui--color_text_primary);
}