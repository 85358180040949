.Avatar {
  border-radius: 50%;
}

.Avatar--has-gradient {
  color: var(--vkui--color_text_contrast);
}

.Avatar--gradient-red {
  background-color: #e52e40;
  background-image: linear-gradient(135deg, #ff7583, #e52e40);
}

.Avatar--gradient-pink {
  background-color: #e62e6b;
  background-image: linear-gradient(135deg, #ff8880, #e62e6b);
}

.Avatar--gradient-orange {
  background-color: #e66b2e;
  background-image: linear-gradient(135deg, #ffbf80, #e66b2e);
}

.Avatar--gradient-yellow {
  background-color: #e7a902;
  background-image: linear-gradient(135deg, #ffd54f, #e7a902);
}

.Avatar--gradient-green {
  background-color: #6cd97e;
  background-image: linear-gradient(135deg, #6cd97e, #12b212);
}

.Avatar--gradient-l-blue {
  background-color: #2bb4d6;
  background-image: linear-gradient(135deg, #7df1fa, #2bb4d6);
}

.Avatar--gradient-blue {
  background-color: #3f8ae0;
  background-image: linear-gradient(135deg, #6cf, #3f8ae0);
}

.Avatar--gradient-violet {
  background-color: #8f3fe0;
  background-image: linear-gradient(135deg, #d3a6ff, #8f3fe0);
}

.Avatar__initials {
  font-family: var(--vkui--font_family_accent);
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
}
