.Caption--caps {
  text-transform: uppercase;
}

.Caption--level-1 {
  font-size: var(--vkui--font_caption1--font_size--regular);
  line-height: var(--vkui--font_caption1--line_height--regular);
  font-weight: var(--vkui--font_caption1--font_weight--regular);
  font-family: var(--vkui--font_caption1--font_family--regular);
}

.Caption--level-1.Caption--caps {
  font-size: var(--vkui--font_caption1_caps--font_size--regular);
  line-height: var(--vkui--font_caption1_caps--line_height--regular);
  font-weight: var(--vkui--font_caption1_caps--font_weight--regular);
  font-family: var(--vkui--font_caption1_caps--font_family--regular);
}

.Caption--level-2 {
  font-size: var(--vkui--font_caption2--font_size--regular);
  line-height: var(--vkui--font_caption2--line_height--regular);
  font-weight: var(--vkui--font_caption2--font_weight--regular);
  font-family: var(--vkui--font_caption2--font_family--regular);
}

.Caption--level-2.Caption--caps {
  font-size: var(--vkui--font_caption2_caps--font_size--regular);
  line-height: var(--vkui--font_caption2_caps--line_height--regular);
  font-weight: var(--vkui--font_caption2_caps--font_weight--regular);
  font-family: var(--vkui--font_caption2_caps--font_family--regular);
}

.Caption--level-3 {
  font-size: var(--vkui--font_caption3--font_size--regular);
  line-height: var(--vkui--font_caption3--line_height--regular);
  font-weight: var(--vkui--font_caption3--font_weight--regular);
  font-family: var(--vkui--font_caption3--font_family--regular);
}

.Caption--level-3.Caption--caps {
  font-size: var(--vkui--font_caption3_caps--font_size--regular);
  line-height: var(--vkui--font_caption3_caps--line_height--regular);
  font-weight: var(--vkui--font_caption3_caps--font_weight--regular);
  font-family: var(--vkui--font_caption3_caps--font_family--regular);
}
