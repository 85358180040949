.FormField {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: var(--vkui--size_field_height--regular);
  font-family: var(--vkui--font_family_base);
  -webkit-tap-highlight-color: transparent;
  isolation: isolate;
  border-radius: var(--vkui--size_border_radius--regular);
}

.FormField--sizeY-compact {
  min-height: var(--vkui--size_field_height--compact);
}

@media (--sizeY-compact) {
  .FormField--sizeY-none {
    min-height: var(--vkui--size_field_height--compact);
  }
}

/* stylelint-disable-next-line @project-tools/stylelint-atomic, selector-max-universal */
.FormField > * {
  border-radius: inherit;
  z-index: var(--vkui_internal--z_index_form_field_element);
}

.FormField__before,
.FormField__after {
  position: relative;
  z-index: var(--vkui_internal--z_index_form_field_side);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: var(--vkui--size_field_height--regular);
  height: 100%;
  color: var(--vkui--color_icon_secondary);
}

.FormField--sizeY-compact .FormField__before,
.FormField--sizeY-compact .FormField__after {
  min-width: var(--vkui--size_field_height--compact);
  height: var(--vkui--size_field_height--compact);
}

@media (--sizeY-compact) {
  .FormField--sizeY-none .FormField__before,
  .FormField--sizeY-none .FormField__after {
    min-width: var(--vkui--size_field_height--compact);
    height: var(--vkui--size_field_height--compact);
  }
}

.FormField__before {
  color: var(--vkui--color_icon_accent);
}

.FormField__after {
  color: var(--vkui--color_icon_secondary);
}

.FormField__border {
  pointer-events: none;
  position: absolute;
  z-index: var(--vkui_internal--z_index_form_field_border);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform-origin: left top;
  border: var(--vkui--size_border--regular) solid transparent;
  border-radius: inherit;
}

.FormField--mode-default .FormField__border {
  border-color: var(--vkui--color_field_border_alpha);
}

.FormField--mode-default {
  background-color: var(--vkui--color_field_background);
}

/**
 * CMP:
 * FormItem
 * [start]
 */
:global(.vkuiInternalFormItem--status-error) .FormField__border,
.FormField--status-error .FormField__border {
  border-color: var(--vkui--color_stroke_negative);
  z-index: var(--vkui_internal--z_index_form_field_status);
}

:global(.vkuiInternalFormItem--status-error) .FormField,
.FormField--status-error {
  background-color: var(--vkui--color_background_negative_tint);
}

:global(.vkuiInternalFormItem--status-valid) .FormField__border,
.FormField--status-valid .FormField__border {
  border-color: var(--vkui--color_stroke_positive);
  z-index: var(--vkui_internal--z_index_form_field_status);
}
/* [end] */

.FormField--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
  cursor: default;
  pointer-events: none;
}

.FormField--hover {
  background-color: var(--vkui--color_field_background);
}

.FormField--mode-default.FormField--hover .FormField__border {
  border-color: var(--vkui--color_field_border_alpha--hover);
  z-index: var(--vkui_internal--z_index_form_field_border_hover);
}

/**
 * CMP:
 * ModalCardBase
 */
:global(.vkuiInternalModalCardBase__header) + .FormField,
:global(.vkuiInternalModalCardBase__subheader) + .FormField {
  margin-top: 16px;
}

/**
 * CMP:
 * NativeSelect
 */
:global(.vkuiInternalNativeSelect) .FormField__after {
  pointer-events: none;
}

/**
 * CMP:
 * CalendarHeader
 */
:global(.vkuiInternalCalendarHeader__picker) .FormField__after {
  min-width: 12px;
  padding-right: 8px;
}

/**
 * CMP:
 * ChipsInput
 */
:global(.vkuiInternalChipsInput) .FormField__after {
  z-index: var(--vkui_internal--z_index_form_field_side);
}

/**
 * CMP:
 * FormLayoutGroup
 */
:global(.vkuiInternalFormLayoutGroup--segmented) .FormField {
  z-index: auto;
  isolation: auto;
  border-radius: 0;
}

:global(
    .vkuiInternalFormLayoutGroup--segmented.vkuiInternalFormLayoutGroup--mode-horizontal
      .vkuiInternalFormItem
  ):first-of-type
  .FormField {
  border-top-left-radius: var(--vkui--size_border_radius--regular);
  border-bottom-left-radius: var(--vkui--size_border_radius--regular);
}

:global(
    .vkuiInternalFormLayoutGroup--segmented.vkuiInternalFormLayoutGroup--mode-horizontal
      .vkuiInternalFormItem
  ):last-of-type
  .FormField {
  border-top-right-radius: var(--vkui--size_border_radius--regular);
  border-bottom-right-radius: var(--vkui--size_border_radius--regular);
}

:global(
    .vkuiInternalFormLayoutGroup--segmented.vkuiInternalFormLayoutGroup--mode-vertical
      .vkuiInternalFormItem
  ):first-of-type
  .FormField {
  border-top-left-radius: var(--vkui--size_border_radius--regular);
  border-top-right-radius: var(--vkui--size_border_radius--regular);
}

:global(
    .vkuiInternalFormLayoutGroup--segmented.vkuiInternalFormLayoutGroup--mode-vertical
      .vkuiInternalFormItem
  ):last-of-type
  .FormField {
  border-bottom-left-radius: var(--vkui--size_border_radius--regular);
  border-bottom-right-radius: var(--vkui--size_border_radius--regular);
}

/**
 * useFocusVisibleClassName()
 */
/* increase specificity for selects */
.FormField--focus-visible.FormField--focus-visible.FormField--focus-visible {
  outline: var(--vkui_internal--outline);
  outline-width: var(--vkui--size_border--regular);
  outline-offset: calc(-1 * var(--vkui--size_border--regular));
}
