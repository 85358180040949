.CalendarDays {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.CalendarDays__row {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.CalendarDays__row:not(:last-child) {
  margin-bottom: 4px;
}

.CalendarDays__row--size-s:not(:last-child) {
  margin-bottom: 0;
}

.CalendarDays__weekday {
  text-transform: capitalize;
  color: var(--vkui--color_text_subhead);
  height: 32px;
  flex-basis: calc(100% / 7);
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
