.ImageBadge {
  position: absolute;
  bottom: -4px;
  right: -4px;
}

.ImageBadge--shifted {
  bottom: -3px;
  right: -3px;
}
