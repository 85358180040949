.Spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_icon_medium);
}

/**
 * CMP:
 * PanelHeader
 */

:global(.vkuiInternalPanelHeader) .Spinner {
  color: currentColor;
}
