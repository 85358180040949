.UsersStack {
  display: flex;
  align-items: center;
  color: var(--vkui--color_text_secondary);
}

.UsersStack--direction-row-reverse {
  flex-direction: row-reverse;
}

.UsersStack--direction-column {
  flex-direction: column;
  justify-content: center;
}

.UsersStack__photos {
  display: flex;
}

.UsersStack__fill {
  fill: var(--vkui--color_image_placeholder_alpha);
}

.UsersStack__photo--others {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_text_contrast_themed);
  background: var(--vkui--color_icon_secondary);
  border-radius: 50%;
}

.UsersStack--size-s .UsersStack__photo {
  width: 16px;
  height: 16px;
}

.UsersStack--size-m .UsersStack__photo {
  width: 24px;
  height: 24px;
}

.UsersStack--size-l .UsersStack__photo {
  width: 32px;
  height: 32px;
}

.UsersStack__photo + .UsersStack__photo {
  margin-left: -2px;
}

.UsersStack__text {
  min-width: 0;
  flex: 1;
}

.UsersStack--direction-column .UsersStack__text {
  text-align: center;
  margin-top: 8px;
}

.UsersStack--direction-row .UsersStack__text {
  margin-left: 8px;
}

.UsersStack--direction-row-reverse .UsersStack__text {
  margin-right: 8px;
}

/**
 * CMP:
 * ModalCardBase
 */
:global(.vkuiInternalModalCardBase) .UsersStack {
  margin-top: 20px;
}
