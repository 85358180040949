.ImageBaseBadge {
  position: absolute;
  z-index: var(--vkui_internal--z_index_image_base_badge);
  right: 0;
  bottom: 0;
  border-radius: 50%;

  /* Смещаем на `padding-top` и `padding-left`, чтобы не учитывать `padding` при позиционировании. */
  transform: translate(2px, 2px);
  padding: 2px;
}

.ImageBaseBadge--background-stroke {
  /* цвет фона для скрывания элементов */
  background-color: var(--vkui_internal--background, var(--vkui--color_background_content));
}

.ImageBaseBadge--background-shadow::before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  margin: auto;
  border-radius: inherit;
  box-shadow: var(--vkui--elevation1);
}
