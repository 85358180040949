.AvatarBadge {
  right: 0;
  bottom: 0;
}

.AvatarBadge--shifted {
  right: -2px;
  bottom: -2px;
}

.AvatarBadge--preset-online,
.AvatarBadge--preset-onlineMobile {
  right: 14%;
  bottom: 14%;
  color: var(--vkui--color_accent_green);
  transform: translate(50%, 50%);
}

/**
 * Перебиваем свойства `ImageBaseBadge` для этих кейсов
 */
.AvatarBadge--preset-online {
  padding: 0;
}

.AvatarBadge--preset-onlineMobile {
  border-radius: 3px;
}
