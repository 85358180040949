.SegmentedControlOption {
  height: 100%;
  border: 0;
  border-radius: inherit;
  background: transparent;
  color: var(--vkui--color_text_primary);
  transition: color 100ms ease-out;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.SegmentedControlOption:hover {
  opacity: 0.5;
}

.SegmentedControlOption__before {
  margin-right: 6px;
}
