.CalendarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 36px;
}

.CalendarHeader__pickers {
  display: flex;
  position: absolute;
  color: var(--vkui--color_text_primary);
}

.CalendarHeader__picker + .CalendarHeader__picker {
  margin-left: 4px;
}

.CalendarHeader__nav-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.CalendarHeader__nav-icon--accent {
  color: var(--vkui--color_icon_accent);
}

.CalendarHeader__nav-icon-next {
  right: 0;
}

.CalendarHeader__nav-icon-prev {
  left: 0;
}

.CalendarHeader__month {
  text-transform: capitalize;
}
