.ModalPageHeader {
  --vkui_internal--safe_area_inset_top: 0;
}

.ModalPageHeader--withGaps {
  padding-right: 4px;
  padding-left: 4px;
}

.ModalPageHeader--desktop.ModalPageHeader--withGaps {
  padding-right: 8px;
  padding-left: 8px;
}

.ModalPageHeader :global(.vkuiIcon--dismiss_24), /* Note: <Icon24Dismiss /> по документации используется только для iOS  */
.ModalPageHeader :global(.vkuiIcon--cancel_24) {
  color: var(--vkui--color_icon_secondary);
}
