.CustomSelect {
  width: 100%;
  position: relative;
  display: block;
  cursor: pointer;
}

.CustomSelect--pop-down {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.CustomSelect--pop-up {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.CustomSelect__control {
  display: none;
}

.CustomSelect__empty {
  padding: 12px 0;
  text-align: center;
  color: var(--vkui--color_text_secondary);
}

.CustomSelect__fetching {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomSelect__dropdown-icon {
  margin-right: 10px;
}

.CustomSelect--clear-icon {
  margin-right: -6px;
}

.CustomSelect--sizeY-compact .CustomSelect__dropdown-icon {
  margin-right: 8px;
}

.CustomSelect--sizeY-compact .CustomSelect--clear-icon {
  margin-right: -2px;
}

@media (--sizeY-compact) {
  .CustomSelect--sizeY-none .CustomSelect__dropdown-icon {
    margin-right: 8px;
  }

  .CustomSelect--sizeY-none .CustomSelect--clear-icon {
    margin-right: -2px;
  }
}
