.ChipsSelect {
  position: relative;
}

.ChipsSelect__dropdown {
  cursor: pointer;
}

.ChipsSelect__icon {
  pointer-events: none;
}

.ChipsSelect__empty {
  padding: 12px 0;
  text-align: center;
  color: var(--vkui--color_text_secondary);
}

.ChipsSelect--pop-down {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ChipsSelect--pop-up {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
