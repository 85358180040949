.Slider {
  position: relative;
  cursor: pointer;
  height: var(--vkui_internal--slider_thumb_size);
}

.Slider--disabled {
  cursor: not-allowed;
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Slider__track,
.Slider__track-fill {
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  border-radius: 1px;
}

.Slider__track {
  width: 100%;
  background-color: var(--vkui--color_track_background);
}

.Slider__track-fill {
  width: auto;
  background: var(--vkui--color_background_accent);
}

.Slider__thumbs {
  isolation: isolate;
  position: relative;
  margin-right: calc(var(--vkui_internal--slider_thumb_size) / 2);
  margin-left: calc(var(--vkui_internal--slider_thumb_size) / 2);
  height: 100%;
}

.Slider__thumb {
  top: 50%;
  transform: translate(-50%, -50%);
}

/**
 * sizeY COMPACT
 */

.Slider--sizeY-compact {
  --vkui_internal--slider_thumb_size: 24px;
}

@media (--sizeY-compact) {
  .Slider--sizeY-none {
    --vkui_internal--slider_thumb_size: 24px;
  }
}
