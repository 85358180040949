.DatePicker {
  margin: 0;
  padding: 0;
  border: 0;
}

.DatePicker__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.DatePicker__day {
  min-width: 72px;
}

.DatePicker__month {
  min-width: 0;
  display: flex;
  flex: 1 0 0;
  padding: 0 8px;
}

.DatePicker__year {
  min-width: 92px;
}

.DatePicker__monthSelect {
  width: 100%;
}
